body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
  padding: 1cm;
  border: 1px black solid;
  height: 256mm;
  outline: 2cm #ffeaea solid;
}

@media print {
  @page {
    size: 8.26in 6.41in !important;
    margin-top: 0.196in !important;
    margin-bottom: 0.05in !important;
    margin-left: 0.1685in !important;
    margin-right: 0.19685in !important;
  }
  /* @page { margin: 0;  } */
  /* body {   size: A5;  margin: 7mm 8mm 9mm 8mm; } */
}
